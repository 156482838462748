import {useEffect, useState} from 'react'
import Modal from '../Modal'
import {EmployerPortalUser, PortalRoleModel, UpdateEmployerPortalUserModel} from '../../redux/types'
import InputField from '../InputField'
import Switch from '../Switch'
import S2 from '../typography/S2'
import {createUseStyles} from 'react-jss'

interface Props {
  initialValue?: EmployerPortalUser | null
  portalRoles: PortalRoleModel[]
  onSaveClick?: (portalUser: UpdateEmployerPortalUserModel) => void
  onCancelClick?: () => void
  visible: boolean
}

const initialState: UpdateEmployerPortalUserModel = {
  identificationNumber: '',
  email: '',
  portalRoleIds: [],
  isContactFinance: false,
  isContactPayroll: false,
  isContactTechnical: false,
  isOwner: false
}

const EditEmployerPortalUserModal = ({
                                       initialValue,
                                       portalRoles,
                                       onSaveClick,
                                       onCancelClick,
                                       visible
                                     }: Props) => {
  const styles = useStyles()
  const [portalUser, setPortalUser] = useState<UpdateEmployerPortalUserModel>(initialState)

  useEffect(() => {
    if (initialValue) {
      setPortalUser({
        identificationNumber: initialValue.identificationNumber,
        email: initialValue.email,
        portalRoleIds: initialValue.portalRoles.map(x => x.id),
        isContactFinance: initialValue.isContactFinance,
        isContactPayroll: initialValue.isContactPayroll,
        isContactTechnical: initialValue.isContactTechnical,
        isOwner: initialValue.isOwner
      })
    }
  }, [initialValue])

  const handleOnSave = () => {
    portalUser && onSaveClick && onSaveClick(portalUser)
    setPortalUser(initialState)
  }

  const saveEnabled = () => {
    return initialValue || ((portalUser.identificationNumber?.length ?? 0) > 0 && (portalUser.email?.length ?? 0) > 0)  
  }

  const handleOnCancel = () => {
    onCancelClick && onCancelClick()
    setPortalUser(initialState)
  }

  const handleOnChange = (propName: string, value: any) => {
    setPortalUser({...portalUser, [propName]: value})
  }

  const handleRoleChange = (role: PortalRoleModel, value: boolean) => {
    if (portalUser) {
      if (value)
        setPortalUser({...portalUser, portalRoleIds: [...portalUser.portalRoleIds, role.id]})
      else
        setPortalUser({...portalUser, portalRoleIds: portalUser.portalRoleIds.filter(x => x !== role.id)})
    }
  }

  return (
    <Modal
      title='Add/edit portal user'
      show={visible}
      okButtonDisabled={!saveEnabled()}
      okButtonTitle='Save'
      cancelButtonTitle='Cancel'
      onHide={() => handleOnCancel()}
      onOkClick={() => handleOnSave()}
      onCancelClick={() => handleOnCancel()}
    >
      <InputField
        id='identificationNumber'
        disabled={initialValue != null}
        value={portalUser?.identificationNumber ?? ''}
        onChange={(event) => handleOnChange('identificationNumber', event.target.value)}
        label='Identification number'
        placeholder=''
      />
      <InputField
        id='email'
        disabled={initialValue != null}
        value={portalUser?.email ?? ''}
        onChange={(event) => handleOnChange('email', event.target.value)}
        label='Email'
        placeholder=''
      />

      <S2 className={styles.label}>Properties</S2>

      <Switch
        id='isOwner'
        key='isOwner'
        checked={portalUser?.isOwner ?? false}
        label='Portal owner'
        onChange={() => handleOnChange('isOwner', !(portalUser?.isOwner ?? false))}
      />
      <Switch
        id='isContactFinance'
        key='isContactFinance'
        checked={portalUser?.isContactFinance ?? false}
        label='Finance contact'
        onChange={() => handleOnChange('isContactFinance', !(portalUser?.isContactFinance ?? false))}
      />
      <Switch
        id='isContactPayroll'
        key='isContactPayroll'
        checked={portalUser?.isContactPayroll ?? false}
        label='Payroll contact'
        onChange={() => handleOnChange('isContactPayroll', !(portalUser?.isContactPayroll ?? false))}
      />
      <Switch
        id='isContactTechnical'
        key='isContactTechnical'
        checked={portalUser?.isContactTechnical ?? false}
        label='Technical contact'
        onChange={() => handleOnChange('isContactTechnical', !(portalUser?.isContactTechnical ?? false))}
      />

      <S2 className={styles.label}>Roles</S2>

      {
        portalRoles.map((role, index) => {
          return (
            <Switch
              id={`role${role.id}`}
              key={`role${role.id}`}
              checked={portalUser?.portalRoleIds?.some(x => x === role.id) ?? false}
              label={role.name}
              onChange={(event) => handleRoleChange(role, event.target.checked)}
            />)
        })
      }

    </Modal>
  )
}

export default EditEmployerPortalUserModal

const useStyles = createUseStyles({
  label: {
    marginTop: 10
  }
})
