import {useEffect, useState} from 'react'
import {createUseStyles} from 'react-jss'
import {useHistory, useParams} from "react-router-dom";
import Switch from '../components/Switch'
import EmployeeList from '../components/EmployeeList'
import {Colors} from '../constants/colors'
import CardSection from '../components/CardSection'
import DetailsCard from '../components/DetailsCard'
import {
  BankAccountModel,
  CreditorConnectionModel,
  EmployerLogoModel, EmployerPortalUser,
  PayoutSettings,
  UpdateEmployerModel, UpdateEmployerPortalUserModel,
} from '../redux/types'
import ConfirmModal from '../components/ConfirmModal'
import EditEmployerPortalUserModal from '../components/EmployerPortalUser/EditEmployerPortalUserModal'
import BankAccountSelectorModal from '../components/BankAccount/BankAccountSelectorModal'
import EditPayoutSettingsModal from '../components/EditPayoutSettingsModal';
import EditEmployerInfoModal from '../components/EditEmployerModal';
import {formatDateTimeString} from '../utils/dateHelper'
import useEmployerActions from '../hooks/useEmployerActions';
import {useAppSelector} from '../redux/hooks';
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator';
import PortalUserList from '../components/EmployerPortalUser/EmployerPortalUserList';
import Divider from '../components/Divider';
import PayScheduleList from '../components/PaySchedule/PayScheduleList';
import CreditorConnectionList from '../components/CreditorConnections/EmployerCreditorList';
import AddCreditorConnectionModal from '../components/CreditorConnections/AddEmployerCreditorModal';
import {faArrowLeft, faPen} from "@fortawesome/free-solid-svg-icons";
import NavLink from '../components/NavLink';
import {titleMarginBottom} from '../constants/layout';
import EmployerLogoList from "../components/EmployerLogo/EmployerLogoList";
import UploadLogoModal from "../components/EmployerLogo/UploadLogoModal";
import EmployerBookkeepingConfiguration from "../components/EmployerBookkeepingConfiguration/EmployerBookkeepingConfiguration";
import Link from '../components/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SilenceCreditSpaceWarningModal from '../components/CreditSpace/SilenceCreditSpaceWarningModal';

const EmployerDetails = () => {
  const styles = useStyles()
  const params = useParams<{id: string}>()
  const history = useHistory();

  const [confirmDeletePortalUserModalVisible, setConfirmDeletePortalUserModalVisible] = useState(false)
  const [confirmLockEmployerModalVisible, setConfirmLockEmployerModalVisible] = useState(false)
  const [confirmSetBankAccountModalVisible, setConfirmSetBankAccountModalVisible] = useState(false)
  const [addPortalUserModalVisible, setAddPortalUserModalVisible] = useState(false)
  const [editPortalUserModalVisible, setEditPortalUserModalVisible] = useState(false)
  const [bankAccountSelectorModalVisible, setBankAccountSelectorModalVisible] = useState(false)
  const [addEmployerCreditorModalVisible, setAddEmployerCreditorModalVisible] = useState(false)
  const [confirmDeleteEmployerCreditorModalVisible, setConfirmDeleteEmployerCreditorModalVisible] = useState(false)
  const [payoutSettingsEditModalVisible, setPayoutSettingsEditModalVisible] = useState(false)
  const [employerEditModalVisible, setEmployerEditModalVisible] = useState(false)
  const [uploadLogoModalVisible, setUploadLogoModalVisible] = useState(false)
  const [confirmDeleteLogoModalVisible, setConfirmDeleteLogoModalVisible] = useState(false)
  const [silenceCreditSpaceWarningModalVisible, setSilenceCreditSpaceWarningModalVisible] = useState(false)

  const [employerCreditorDeleteCandidate, setEmployerCreditorDeleteCandidate] = useState<CreditorConnectionModel | null>(null)
  const [portalUserDeleteCandidate, setPortalUserDeleteCandidate] = useState<EmployerPortalUser | null>(null)
  const [bankAccountCandidate, setBankAccountCandidate] = useState<{id: string, number: string} | null>(null)
  const [editedPortalUser, setEditedPortalUser] = useState<EmployerPortalUser | null>(null)
  const [employerLogoDeleteCandidate, setEmployerLogoDeleteCandidate] = useState<EmployerLogoModel | null>(null)

  const loading = useAppSelector(state => state.employer.loading)
  const employer = useAppSelector(state => state.employer.employerDetails)

  const {
    getEmployerDetails,
    setBankAccount,
    setLockState,
    updatePayoutSettings,
    updateEmployerInfo,
    addPortalUser,
    updatePortalUser,
    deletePortalUser,
    addEmployerCreditor,
    deleteEmployerCreditor,
    uploadEmployerLogo,
    deleteEmployerLogo,
    silenceCreditSpaceWarning
  } = useEmployerActions()

  const inherited = '(inherited)'

  useEffect(() => {
    if (employer?.id == null || employer.id !== params.id) {
      getEmployerDetails(params.id)
    }
  }, [])

  const handleDeleteEmployerCreditor = (creditor: CreditorConnectionModel) => {
    setEmployerCreditorDeleteCandidate(creditor)
    setConfirmDeleteEmployerCreditorModalVisible(true)
  }

  const handleConfirmDeleteEmployerCreditor = () => {
    deleteEmployerCreditor(employer?.id as string, employerCreditorDeleteCandidate?.id || '')
    setEmployerCreditorDeleteCandidate(null)
    setConfirmDeleteEmployerCreditorModalVisible(false)
  }

  const handleAddEmployerCreditor = (creditor: CreditorConnectionModel) => {
    addEmployerCreditor(employer?.id as string, creditor)
    setAddEmployerCreditorModalVisible(false)
  }

  const handleBankAccountSelected = (bankAccount: BankAccountModel) => {
    setBankAccountCandidate({id: bankAccount.bankAccountId, number: bankAccount.bban})
    setConfirmSetBankAccountModalVisible(true)
    setBankAccountSelectorModalVisible(false)
  }

  const handleLockStateChange = () => {
    const isLock = employer?.state === 'active'
    if (isLock) {
      setConfirmLockEmployerModalVisible(true)
    } else {
      setLockState(employer?.id as string, false)
    }
  }

  const handleLockEmployerConfirmed = () => {
    setLockState(employer?.id as string, true)
    setConfirmLockEmployerModalVisible(false)
  }

  const handleSetBankAccountConfirmed = () => {
    if (bankAccountCandidate)
      setBankAccount(employer?.id as string, bankAccountCandidate.id, bankAccountCandidate.number)

    setConfirmSetBankAccountModalVisible(false)
  }

  const handlePayoutSettingsChange = (payoutSettings: PayoutSettings) => {
    updatePayoutSettings(employer?.id as string, payoutSettings)
    setPayoutSettingsEditModalVisible(false)
  }

  const handleEmployerInfoChange = (employerInfo: UpdateEmployerModel) => {
    updateEmployerInfo(employer?.id as string, employerInfo)
    setEmployerEditModalVisible(false)
  }

  const handleSilenceCreditSpaceWarning = (days: number) => {
    setSilenceCreditSpaceWarningModalVisible(false)
    silenceCreditSpaceWarning(employer?.id as string, days)
  }

  const handleEditPortalUserClicked = (portalUser: EmployerPortalUser) => {
    setEditedPortalUser(portalUser)
    setEditPortalUserModalVisible(true)
  }

  const handleDeletePortalUserClicked = (portalUser: EmployerPortalUser) => {
    setPortalUserDeleteCandidate(portalUser)
    setConfirmDeletePortalUserModalVisible(true)
  }

  const handleDeletePortalUserConfirmed = () => {
    deletePortalUser(employer?.id as string, portalUserDeleteCandidate?.portalUserId as string)
    setConfirmDeletePortalUserModalVisible(false)
  }

  const handlePortalUserEdited = (portalUser: UpdateEmployerPortalUserModel) => {
    if (!!employer && !!editedPortalUser) {
      updatePortalUser(employer.id, editedPortalUser.portalUserId, portalUser)
      setEditedPortalUser(null)
    }
    setEditPortalUserModalVisible(false)
  }

  const handlePortalUserAdded = (portalUser: UpdateEmployerPortalUserModel) => {
    if (!!employer) {
      addPortalUser(employer.id, portalUser)
    }
    setAddPortalUserModalVisible(false)
  }

  const handleUploadLogo = (theme: string, file: File) => {
    uploadEmployerLogo(employer?.id as string, theme, file)
    setUploadLogoModalVisible(false)
  }

  const handleDeleteEmployerLogo = (logo: EmployerLogoModel) => {
    setEmployerLogoDeleteCandidate(logo)
    setConfirmDeleteLogoModalVisible(true)
  }

  const handleConfirmDeleteEmployerLogo = () => {
    deleteEmployerLogo(employer?.id as string, employerLogoDeleteCandidate?.theme as string)
    setConfirmDeleteLogoModalVisible(false)
  }

  const renderCompanyInformationCardBody = () => {
    return (
      <div>
        <p>Customer: <Link to={`/customers/${employer?.customerId}`}>{employer?.customerName}</Link></p>
        <p>Abbreviation: <span>{employer?.appAbbreviation}</span></p>
        <p>Admin flow: <span>{employer?.adminFlowType}</span></p>
        <p>Employee activation type: <span>{employer?.employeeActivationType}</span></p>
        <p>Employee deactivation type: <span>{employer?.employeeDeactivationType}</span></p>
        <p>Default tax table: <span>{employer?.defaultTaxTableIdentifier}</span></p>
        <p>Default fix income tax: <span>{employer?.defaultFixedTaxRatePercent}%</span></p>
        <p>Default payout tax: <span>{employer?.defaultPayoutTaxInPercent}%</span></p>
        <p>Payout tax immutable: <span>{employer?.payoutTaxImmutable ? "yes" : "no"}</span></p>
        <p>Payroll system: <span>{employer?.payrollSystem}</span></p>
        <p>Payroll file format: <span>{employer?.payrollFileFormat}</span></p>
        <p>Manual payroll file row creation enabled: <span>{employer?.manualPayrollFileRowCreationEnabled ? "yes" : "no"}</span></p>
        <p>Payroll system import file format: <span>{employer?.payrollSystemImportFileFormat}</span></p>
        <p>Enable payroll file adjustment: <span>{employer?.enablePayrollFileAdjustment ? "yes" : "no"}</span></p>
        <p>Payroll file employee identification: <span>{employer?.payrollFileEmployeeIdentifierType}</span></p>
        <p>Enable payroll system bank account import file: <span>{employer?.enablePayrollSystemBankAccountImportFile ? "yes" : "no"}</span></p>
        <p>Payroll system bank account import file custom field: <span>{employer?.payrollSystemBankAccountImportFileCustomField}</span></p>
        <p>Employee data parser type: <span>{employer?.employeeDataParserType}</span></p>
        <p>Shift data parser type: <span>{employer?.shiftDataParserType}</span></p>
        <p>Disable deduction file generation: <span>{employer?.disablePaydayDeductionFileGeneration ? "yes" : "no"}</span></p>
        <p>Employer deduction payroll code for wages: <span>{employer?.wagesPayrollCodeForPaydayDeductions}</span></p>
        <p>Employer deduction payroll code for salaries: <span>{employer?.salaryPayrollCodeForPaydayDeductions}</span></p>
        <p>Calendar month payout basis payroll code for wages: <span>{employer?.wagesPayrollCodeForCalendarMonthPayoutBasis}</span></p>
        <p>Calendar month payout basis payroll code for salaries: <span>{employer?.salaryPayrollCodeForCalendarMonthPayoutBasis}</span></p>
        <p>Payroll code for vacation pay: <span>{employer?.vacationPayPayrollCode}</span></p>
        <p>Default percentage for vacation pay: <span>{employer?.defaultVacationPayPercent}</span></p>
        <p>Calendar month payout basis report generation: <span>{employer?.calendarMonthPayoutBasisReportGenerationType}</span></p>
        <p>Day of month for early calendar month payout basis report generation: <span>{employer?.calenderMonthPayoutBasisReportGenerationDayOfMonth ?? 'Not set'}</span></p>
        <p>Activation freeze days before payday: <span>{employer?.employeeActivationFreezeDaysBeforePayday}</span></p>
        <p>Free withdrawal count on activation: <span>{employer?.freePayoutCountOnEmployeeActivation}</span></p>
        <p>Launch date: <span>{employer?.launchDate != null ? formatDateTimeString(employer?.launchDate) : 'Not specified'}</span></p>
        <p>Last data sync: <span>{employer?.lastShiftOrSalaryUpdate != null ? formatDateTimeString(employer.lastShiftOrSalaryUpdate) : 'N/A'}</span></p>
        <p>Send new employee welcome messages: <span>{employer?.newEmployeeMessageEnabled ? "yes" : "no"}</span></p>
        <p>Send employee activation reminders: <span>{employer?.employeeActivationReminderEnabled ? "yes" : "no"}</span></p>
        <p>Activation reminder interval (days): <span>{employer?.employeeActivationReminderIntervalDays}</span></p>
        <p>Planned shifts enabled: <span>{employer?.plannedShiftsEnabled ? "yes" : "no"}</span></p>
        <p>New employees report enabled: <span>{employer?.newEmployeesReportEnabled ? "yes" : "no"}</span></p>
        <p>Payroll file execution date one day before payment: <span>{employer?.payrollFileExecutionDateOneDayBeforePayment ? "yes" : "no"}</span></p>
        <p>Allow shifts and deviations outside current employment bounds: <span>{employer?.allowShiftsAndDeviationsOutsideCurrentEmploymentBounds ? "yes" : "no"}</span></p>
        <p>Employer initiated payouts enabled: <span>{employer?.employerInitiatedPayoutsEnabled ? "yes" : "no"}</span></p>
        <p>Employer initiated payout tax percent: <span>{employer?.employerInitiatedPayoutTaxPercent}</span></p>
        <p>Employer initiated payout config: <span>{employer?.employerInitiatedPayoutClientConfiguration}</span></p>
        <p>Employee min age: <span>{employer?.employeeMinAgeInYears}</span></p>
        <p>Portal auth scheme: <span>{employer?.defaultPortalUserAuthenticationScheme}</span></p>
      </div>
    )
  }

  const renderBankInformationCardBody = () => {
    return (
      <div>
        <p>Bank account number: <span>{employer?.bankAccount?.bankAccountNumber ?? 'Not specified'}</span></p>
      </div>
    )
  }

  const renderNotificationCardBody = () => {
    return (
      <div>
        <p>Activation notifications: <span>{employer?.activationNotificationDaysOfWeek?.join(', ') ?? 'Not specified'}</span></p>
      </div>
    )
  }

  const renderCreditCardBody = () => {
    return (
      <div>
        <p>Credit limit: <span>{employer?.creditLimit?.amount.toLocaleString('sv-SE')} {employer?.creditLimit?.currencyCode.toUpperCase()}</span></p>
        <p>Credit usage: <span>{employer?.creditUsage?.amount.toLocaleString('sv-SE')} {employer?.creditUsage?.currencyCode.toUpperCase()}</span></p>
        <p>Remaining credit space warning: <span>{employer?.remainingCreditSpaceWarningPercent}%</span></p>
        <p>Credit space warning silenced until: <span>{formatDateTimeString(employer?.creditSpaceWarningSilencedUntilDate)}</span>
          <FontAwesomeIcon
            onClick={() => setSilenceCreditSpaceWarningModalVisible(true)}
            className={styles.inlineIcon}
            icon={faPen}
            color={Colors.euro_600}
          />
        </p>
      </div>
    )
  }

  const renderPayoutSettingsCardBody = () => {
    return (
      <div>
        <p>Min amount per payout: <span>{employer?.payoutSettings?.minAmountPerPayout != null ? employer?.payoutSettings?.minAmountPerPayout : `${employer?.effectivePayoutSettings?.minAmountPerPayout} ${inherited}`}</span></p>
        <p>Max amount per pay period: <span>{employer?.payoutSettings?.maxAmountPerPayday != null ? employer?.payoutSettings?.maxAmountPerPayday : `${employer?.effectivePayoutSettings?.maxAmountPerPayday} ${inherited}`}</span></p>
        <p>Max % per pay period: <span>{employer?.payoutSettings?.maxPercentPerPayday != null ? employer?.payoutSettings?.maxPercentPerPayday : `${employer?.effectivePayoutSettings?.maxPercentPerPayday} ${inherited}`}</span></p>
        {/* <p>Max amount per payout: <span>{employer?.payoutSettings?.maxAmountPerPayout || notSpecified}</span></p> */}
        <p>Max # of payouts per pay period: <span>{employer?.payoutSettings?.maxNumberOfPayoutsPerPayday != null ? employer?.payoutSettings?.maxNumberOfPayoutsPerPayday : `${employer?.effectivePayoutSettings?.maxNumberOfPayoutsPerPayday ?? '∞'} ${inherited}`}</span></p>
        <p>Fee: <span>{employer?.payoutSettings?.fee != null ? employer?.payoutSettings?.fee : `${employer?.effectivePayoutSettings?.fee} ${inherited}`}</span></p>
        <p>Currency: <span>{employer?.payoutSettings?.currency != null ? employer?.payoutSettings?.currency?.toUpperCase() : `${employer?.effectivePayoutSettings?.currency?.toUpperCase()} ${inherited}`}</span></p>
        <p>Enable custom shift settings: <span>{employer?.payoutSettings?.enableCustomShiftSettings != null ? employer?.payoutSettings?.enableCustomShiftSettings?.toString() : `${employer?.effectivePayoutSettings?.enableCustomShiftSettings} ${inherited}`}</span></p>
        <p>Max custom % per pay period: <span>{employer?.payoutSettings?.maxCustomPercentPerPayday != null ? employer?.payoutSettings?.maxCustomPercentPerPayday : `${employer?.effectivePayoutSettings?.maxCustomPercentPerPayday} ${inherited}`}</span></p>
      </div>
    )
  }

  const renderLockedSwitch = () => {
    const isLocked = employer?.state === 'locked'
    return (
      <Switch
        id='lockSwitch'
        key='lockSwitch'
        checked={isLocked}
        label={isLocked ? 'Manual lock on' : 'Manual lock off'}
        onChange={() => handleLockStateChange()} />
    )
  }

  return (
    <div className={styles.container}>
      <h1>Employer: {employer?.customerName}</h1>
      <div className={styles.actionBar}>
        <div className={styles.actionBarCell} style={{justifyContent: 'flex-start'}}>
          <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()} />
        </div>
        <div className={styles.actionBarCell} style={{justifyContent: 'flex-end'}}>
            <div style={{paddingBottom: 8}}>
              { renderLockedSwitch() }
            </div>
        </div>
      </div>
      <CardSection>
        <DetailsCard onClick={() => setEmployerEditModalVisible(true)}>
          <h6>General information</h6>
          {renderCompanyInformationCardBody()}
        </DetailsCard>
        <div className={styles.cardColumnContainer}>
          <DetailsCard onClick={() => setEmployerEditModalVisible(true)}>
            <h6>Credit information</h6>
            {renderCreditCardBody()}
          </DetailsCard>
          <DetailsCard iconType='Add' onClick={() => setAddEmployerCreditorModalVisible(true)}>
            <h6>Creditors</h6>
            <CreditorConnectionList
              creditors={employer?.creditors ?? []}
              onDeleteClick={handleDeleteEmployerCreditor}
            />
          </DetailsCard>
        </div>
        <div className={styles.cardColumnContainer}>
          <DetailsCard onClick={() => setPayoutSettingsEditModalVisible(true)}>
            <h6>Payout settings</h6>
            {renderPayoutSettingsCardBody()}
          </DetailsCard>
          <DetailsCard onClick={() => setUploadLogoModalVisible(true)}>
            <h6>Logos</h6>
            <EmployerLogoList
              employerLogos={employer?.employerLogos ?? []}
              onDeleteClick={handleDeleteEmployerLogo}
            />
          </DetailsCard>
        </div>
      </CardSection>
      <CardSection>
        <DetailsCard onClick={!employer?.bankAccount?.id ? () => setBankAccountSelectorModalVisible(true) : undefined}>
          <h6>Bank information</h6>
          {renderBankInformationCardBody()}
        </DetailsCard>
        <DetailsCard>
          <h6>Notifications</h6>
          {renderNotificationCardBody()}
        </DetailsCard>
      </CardSection>

      <CardSection>
        <DetailsCard iconType='Add' onClick={() => setAddPortalUserModalVisible(true)}>
            <h6>Portal users</h6>
            <PortalUserList
              portalUsers={employer?.portalUsers ?? []}
              onEditClick={handleEditPortalUserClicked}
              onDeleteClick={handleDeletePortalUserClicked}
            />
        </DetailsCard>
      </CardSection>
      <CardSection>
        <DetailsCard iconType='Add' onClick={() => history.push(`/employers/${params.id}/payschedules/create`)}>
            <h6>Pay schedules</h6>
            {employer && <PayScheduleList
              paySchedules={employer.paySchedules}
              employerId={employer.id}
            />}
        </DetailsCard>
      </CardSection>
      <CardSection>
        <DetailsCard >
          <h6>Employees</h6>
          <EmployeeList employerId={employer?.id} />
        </DetailsCard>
      </CardSection>
      {employer?.bookkeepingConfiguration &&
          <CardSection>
              <DetailsCard>
                  <h6>Bookkeeping settings</h6>
                  <EmployerBookkeepingConfiguration bookkeepingConfiguration={employer.bookkeepingConfiguration}/>
              </DetailsCard>
          </CardSection>
      }
      <Divider height={50}/>
       <ConfirmModal
        visible={confirmDeletePortalUserModalVisible}
        confirmText={`Are you sure you want to remove portal user with email ${portalUserDeleteCandidate?.email}?`}
        onConfirmClick={handleDeletePortalUserConfirmed}
        onCancelClick={() => setConfirmDeletePortalUserModalVisible(false)}
      />
      <ConfirmModal
        visible={confirmLockEmployerModalVisible}
        confirmText={`Are you sure you want to lock this employer? Users connected to this employer will be paused.`}
        onConfirmClick={handleLockEmployerConfirmed}
        onCancelClick={() => setConfirmLockEmployerModalVisible(false)}
      />
      <ConfirmModal
        visible={confirmSetBankAccountModalVisible}
        confirmText={`Are you sure you want to connect this bank account? This cannot be changed.`}
        onConfirmClick={handleSetBankAccountConfirmed}
        onCancelClick={() => setConfirmSetBankAccountModalVisible(false)}
      />
      <ConfirmModal
        visible={confirmDeleteEmployerCreditorModalVisible}
        confirmText={`Are you sure you want to remove ${employerCreditorDeleteCandidate?.name} as a creditor?`}
        onConfirmClick={handleConfirmDeleteEmployerCreditor}
        onCancelClick={() => setConfirmDeleteEmployerCreditorModalVisible(false)}
      />
      <AddCreditorConnectionModal
        visible={addEmployerCreditorModalVisible}
        marketId={employer?.marketId ?? ''}
        existingCreditorConnectionModels={employer?.creditors ?? []}
        onAddClick={handleAddEmployerCreditor}
        onCancelClick={() => setAddEmployerCreditorModalVisible(false)}
      />
      {employer && <EditEmployerPortalUserModal
        key='addEmployerPortalUserModal'
        portalRoles={employer.portalRoles}
        onCancelClick={() => setAddPortalUserModalVisible(false)}
        onSaveClick={handlePortalUserAdded}
        visible={addPortalUserModalVisible}
      />}
      {employer && <EditEmployerPortalUserModal
        key='editEmployerPortalUserModal'
        initialValue={editedPortalUser}
        portalRoles={employer.portalRoles}
        onSaveClick={handlePortalUserEdited}
        onCancelClick={() => {
          setEditPortalUserModalVisible(false)
          setEditedPortalUser(null)}}
        visible={editPortalUserModalVisible}
      />}
      <BankAccountSelectorModal
        marketId={employer?.marketId ?? ''}
        visible={bankAccountSelectorModalVisible}
        onSelectClick={handleBankAccountSelected}
        onCancelClick={() => setBankAccountSelectorModalVisible(false)}
      />
      <EditPayoutSettingsModal
        visible={payoutSettingsEditModalVisible}
        currencyEditable={false}
        initialValue={employer?.payoutSettings}
        marketId={employer?.marketId ?? ''}
        onCancelClick={() => setPayoutSettingsEditModalVisible(false)}
        onSaveClick= {handlePayoutSettingsChange}
      />
      <EditEmployerInfoModal
        visible={employerEditModalVisible}
        initialValue={employer ?? undefined}
        onCancelClick={() => setEmployerEditModalVisible(false)}
        onSaveClick={handleEmployerInfoChange}
      />
      <UploadLogoModal
        visible={uploadLogoModalVisible}
        onUpload={(theme, file) => {handleUploadLogo(theme, file)}}
        onCancelClick={() => setUploadLogoModalVisible(false)}/>
      <ConfirmModal
        visible={confirmDeleteLogoModalVisible}
        confirmText={`Are you sure you want to remove the ${employerLogoDeleteCandidate?.theme} logo?`}
        onConfirmClick={handleConfirmDeleteEmployerLogo}
        onCancelClick={() => setConfirmDeleteLogoModalVisible(false)}
      />
      <SilenceCreditSpaceWarningModal
        visible={silenceCreditSpaceWarningModalVisible}
        onCancelClick={() => setSilenceCreditSpaceWarningModalVisible(false)}
        onSaveClick={handleSilenceCreditSpaceWarning}
      />
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default EmployerDetails

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    },
    '& h5': {
      fontFamily: 'Aestetico-Regular',
      color: Colors.darkDefault,
    }
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: -8
  },
  actionBarCell: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 15,
    alignItems: 'center',
    width: '33%'
  },
  navLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10
  },
  cardColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10
  },
  largeButton: {
    fontSize: "xxx-large",
    alignSelf: "center",
    margin: "20px",
    flexGrow: 1,
    transition: '0.4s',
    '&:hover': {
      color: Colors.euro5,
      cursor: 'pointer',
    }
  },
  inlineIcon: {
    marginLeft: 5,
    '&:hover': {
      color: Colors.euro5,
      cursor: 'pointer',
    }
  }
})
